import { adminClient } from "@api/admin/AdminClient";
import { Box, Card, CardContent, CardHeader, Table, TableBody, TableCell, TableRow, Typography } from "@material-ui/core";
import { AdminUsersFirstTradeTimesGetResponse } from "@reshopper/admin-client";
import { useEffect, useState } from "react";

export default function BuyersAndSellersTableComponent() {

	const [usersFirstTradeTimesGetResponse, setUsersFirstTradeTimesGetResponse] = useState<AdminUsersFirstTradeTimesGetResponse>();

	useEffect(() => {
		const getUsersFirstTradeTimesGetResponse = async () => {
			const result = await adminClient().adminUsersFirstTradeTimesGet();
			setUsersFirstTradeTimesGetResponse(result);
		}

		getUsersFirstTradeTimesGetResponse();
	}, [])

	return <>
		<Card style={{ width: "32.277%", backgroundColor: "#fafafa", justifyContent: 'space-between', margin: "10px", fontSize: '100%' }}>
			<CardHeader style={{ background: '#62AED6' }} title={
				<Box>
					<Typography style={{ color: 'rgb(255, 255, 255)', fontWeight: "bold" }} variant="h6">Totals</Typography>
				</Box>
			}>
			</CardHeader>
			<CardContent style={{ padding: "16px", backgroundColor: "#fafafa" }}>
				<Table>
					<TableBody>
						<TableRow style={{ borderBottom: "2px solid rgba(224, 224, 224, 1)" }}>
							<TableCell style={{ border: 0, paddingLeft: 0 }}>
								<Typography style={{ color: "rbg(96, 69, 63)" }} variant="body1">Sellers</Typography>
								<Typography style={{ color: "rbg(96, 69, 63)" }} display="inline" variant="body2">Users who sold something</Typography>
								<Typography style={{float: "right"}} variant="body2">{usersFirstTradeTimesGetResponse?.totalUsersThatHaveSoldSomething.toLocaleString()}</Typography>
							</TableCell>
						</TableRow>
						<TableRow style={{ borderBottom: "2px solid rgba(224, 224, 224, 1)" }}>
							<TableCell style={{ border: 0, paddingLeft: 0 }}>
								<Typography style={{ color: "rbg(96, 69, 63)" }} variant="body1">Buyers</Typography>
								<Typography style={{ color: "rbg(96, 69, 63)" }} display="inline" variant="body2">Users who ever purchased something</Typography>
								<Typography style={{float: "right"}} variant="body2">{usersFirstTradeTimesGetResponse?.totalUsersThatHaveBoughtSomething.toLocaleString()}</Typography>
							</TableCell>
						</TableRow>
						<TableRow style={{ borderBottom: "2px solid rgba(224, 224, 224, 1)" }}>
							<TableCell style={{ border: 0, paddingLeft: 0 }}>
								<Typography style={{ color: "rbg(96, 69, 63)" }} variant="body1">Both</Typography>
								<Typography style={{ color: "rbg(96, 69, 63)" }} display="inline" variant="body2">Users who both sold and purchased something</Typography>
								<Typography style={{float: "right"}} variant="body2">{usersFirstTradeTimesGetResponse?.totalUsersThatHaveSoldAndBoughtSomething.toLocaleString()}</Typography>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell style={{ border: 0, paddingLeft: 0 }}>
								<Typography style={{ color: "rbg(96, 69, 63)" }} variant="body1">None</Typography>
								<Typography style={{ color: "rbg(96, 69, 63)" }} display="inline" variant="body2">Users who never did anything</Typography>
								<Typography style={{float: "right"}} variant="body2">{usersFirstTradeTimesGetResponse?.totalUsersThatHaveNotSoldAndBoughtSomething.toLocaleString()}</Typography>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</CardContent>
		</Card>
		<Card style={{ width: "32.277%", backgroundColor: "#fafafa", justifyContent: 'space-between', margin: "10px", fontSize: '100%' }}>
			<CardHeader style={{ background: '#62AED6' }} title={
				<Box>
					<Typography style={{ color: 'rgb(255, 255, 255)', fontWeight: "bold" }} variant="h6">Time before (users created within last 365 days)</Typography>
				</Box>
			}>
			</CardHeader>
			<CardContent style={{ padding: "16px", backgroundColor: "#fafafa" }}>
				<Table>
					<TableBody>
						<TableRow style={{ borderBottom: "2px solid rgba(224, 224, 224, 1)" }}>
							<TableCell style={{ border: 0, paddingLeft: 0 }}>
								<Typography align="left" display="inline" variant="body1">First item created</Typography>
								<Typography style={{float: "right"}} variant="body2"> {usersFirstTradeTimesGetResponse?.averageDaysBeforeFirstItemCreated.toPrecision(3)} days</Typography>
							</TableCell>
						</TableRow>
						<TableRow style={{ borderBottom: "2px solid rgba(224, 224, 224, 1)" }}>
							<TableCell style={{ border: 0, paddingLeft: 0 }}>
								<Typography align="left" display="inline" variant="body1">First item sold</Typography>
								<Typography style={{float: "right"}} variant="body2"> {usersFirstTradeTimesGetResponse?.averageDaysBeforeFirstItemSold.toPrecision(3)} days</Typography>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell style={{ border: 0, paddingLeft: 0 }}>
								<Typography align="left" display="inline" variant="body1">First item purchased</Typography>
								<Typography style={{float: "right"}} variant="body2"> {usersFirstTradeTimesGetResponse?.averageDaysBeforeFirstItemPurchased.toPrecision(3)} days</Typography>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</CardContent>
		</Card>
	</>
}